import OT from '@opentok/client';
import _ from 'lodash';
import { mockChatInfo4Users as fixtureSessionInfo } from '@shared/VideoChat/__fixtures__/chatInfo';
import { CHAT_ENDPOINTS, CHAT_SERVER } from '../servers';
import { postRequest, graphMutation } from '../commService';
import { postMessage } from '../messageService';

const useFixtures = process.env.USE_FIXTURES;

// insert here generated data from Opentok Admin panel to use fixture mode
const fixtureToken = 'T1==cGFydG5lcl9pZD00NjIwMTk4MiZzaWc9ZDY1MzI4ZDExNmFmNjU1ZTUzZDlkZGM0OGUwYjI0ZGJjMDg0MzQ4MTpzZXNzaW9uX2lkPTJfTVg0ME5qSXdNVGs0TW41LU1UVTBOekV5T1RBMk5ETXpPSDVQVm5OTVVUZHpWRGhSUWpWTGRFZHZRWGRvVWpscU16Si1mZyZjcmVhdGVfdGltZT0xNTQ3MTI5MDg0Jm5vbmNlPTAuMjEzMDEwNjk3MjY2NDQxODQmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTU0OTcyMTA4MyZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==';
const fixtureApiKey = '46201982';
const fixtureSessionId = '2_MX40NjIwMTk4Mn5-MTU0NzEyOTA2NDMzOH5PVnNMUTdzVDhRQjVLdEdvQXdoUjlqMzJ-fg';
const USER_NO_PERMISSION_ERROR = 1500;

export const ScreenSharingRoleEnum = Object.freeze({
  NONE: Symbol('NONE'),
  SUBSCRIBER: Symbol('SUBSCRIBER'),
  PUBLISHER: Symbol('PUBLISHER'),
});
export const VideoTypeEnum = Object.freeze({
  SCREEN: 'screen',
});

class ChatService {
  constructor() {
    // shared variables
    this.session = null;
    this.cameraPublisher = null; // for camera publishing
    this.screenPublisher = null; // for screen sharing
    this.publisherScreenRec = null; // used separately for screen recording
    this.sessionId = null;
    this.token = null;
    this.APIKey = null;
    this.screenRecording = null;

    // variables related to video chat
    this.isSelfAudioOn = true;
    this.isSelfVideoOn = true;
    this.participantId = null;
    this.chatId = null;

    // variables related to video mirror
    this.archiveId = null;
    this.activityContext = null;

    // variables related to screen sharing
    this.screenSharingRole = ScreenSharingRoleEnum.NONE;

    // number of subscriber
    this.numSubscribers = 0;
    this.userHasPermission = true;
  }

  initiateChat = async (inviteIds) => {
    try {
      if (useFixtures) {
        this.sessionId = fixtureSessionId;
      } else {
        const response = await postRequest(
          CHAT_SERVER,
          CHAT_ENDPOINTS.INITIATE_CHAT,
          { inviteIds },
        );
        this.chatId = response.body.chatId;
      }
      return this.chatId;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  };

  getChatInfo = async (chatId) => {
    try {
      if (useFixtures) {
        this.sessionId = fixtureSessionId;
        this.APIKey = fixtureApiKey;
        this.token = fixtureToken;
        const user = _.find(fixtureSessionInfo.users, { self: true });
        this.participantId = user ? user.participantId : null;
        return fixtureSessionInfo;
      }
      const response = await postRequest(
        CHAT_SERVER,
        CHAT_ENDPOINTS.GET_CHAT_INFO,
        { chatId },
      );
      this.chatId = chatId;
      this.APIKey = response.body.apiKey;
      this.token = response.body.token;
      this.sessionId = response.body.sessionInfo.sessionId;
      const user = _.find(response.body.sessionInfo.users, { self: true });
      this.participantId = user ? user.participantId : null;
      return response.body.sessionInfo;
    } catch (error) {
      return { error };
    }
  };

  getParticipantId = async (event) => {
    try {
      const response = await postRequest(
        CHAT_SERVER,
        CHAT_ENDPOINTS.GET_PARTICIPANT_ID_BY_CONNECTION_ID,
        { sessionId: this.sessionId, connectionId: event.stream.connection.id },
      );
      if (response) {
        return response.body.participantId;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  setConnectionId = async () => {
    const { chatId } = this;
    const { connectionId } = this.session.connection;
    if (useFixtures) {
      return true;
    }
    const response = await postRequest(
      CHAT_SERVER,
      CHAT_ENDPOINTS.SET_CONNECTION_ID,
      { connectionId, chatId },
    );
    return response.body.connectionSet;
  };

  connectToSession = () => new Promise((resolve, reject) => {
    this.session.connect(this.token, (error) => {
      if (error) reject(error);
      else resolve();
    });
  });

  connectToChat = async (
    users,
    {
      onMessageReceived,
      onSessionDisconnected,
      onSessionConnected,
      onStreamPropertyChanged,
      onStreamDestroyed,
      onStreamCreated,
    },
  ) => {
    const publisher = _.find(users, { self: true });

    this.session = OT.initSession(this.APIKey, this.sessionId);

    // Create a camera publisher 
    this.cameraPublisher = OT.initPublisher(
      'publisher',
      {
        name: publisher.participantId,
        insertMode: 'append',
        width: '100%',
        height: '100%',
        showControls: false,
      },
      this.handleError,
    );

    // Subscribe to a newly created stream
    this.session.on('streamCreated', async (event) => {
      if (event.stream.videoType === VideoTypeEnum.SCREEN) {
        this.session.subscribe(event.stream, 'ScreenSharingWrapper', {
          name: event.stream.name,
          insertMode: 'append',
          width: '100%',
          height: '100%',
          showControls: false,
        });
        this.screenSharingRole = ScreenSharingRoleEnum.SUBSCRIBER;
      } else {
        this.numSubscribers += 1;
        const participantId = await this.getParticipantId(event);
        if (participantId) {
          this.session.subscribe(
            event.stream,
            participantId,
            {
              name: event.stream.name,
              insertMode: 'append',
              width: '100%',
              height: '100%',
              showControls: false,
            },
            this.handleError,
          );
          onStreamCreated(event);
        }
      }
    });

    this.session.on('streamPropertyChanged', (event) => {
      onStreamPropertyChanged(event);
    });

    this.session.on('signal:msg', (event) => {
      onMessageReceived(event.data);
    });

    this.session.on('sessionConnected', () => {
      onSessionConnected();
    });

    this.session.on('sessionDisconnected', () => {
      this.numSubscribers = 0;
      onSessionDisconnected();
      this.screenSharingRole = ScreenSharingRoleEnum.NONE;
    });

    // This event is called when a user other than the current user leaves the session.
    this.session.on('streamDestroyed', (event) => {
      if (event.stream.videoType === VideoTypeEnum.SCREEN) {
        this.screenSharingRole = ScreenSharingRoleEnum.NONE;
      }
      this.numSubscribers = 0;
      onStreamDestroyed(event);
    });

    // Connect to the session
    try {
      await this.connectToSession();
      // Publish the camera feed
      this.session.publish(this.cameraPublisher, this.handleError);
      await this.setConnectionId();
    } catch (error) {
      this.handleError(error);
    }
    return this.userHasPermission;
  };

  handleCallback = (error) => {
    if (error) {
      throw new Error(`error: ${error.message}`);
    }
  };

  screenRecordingHelper = async () => {
    try {
      // Create a new publisher for the screen stream (recording usage)
      this.publisherScreenRec = await OT.initPublisher(
        'screenRecordPublisher',
        {
          name: 'screenRecordPublisher',
          insertMode: 'replace',
          width: '100%',
          height: '100%',
          videoSource: 'screen',
          publishAudio: false,
          publishVideo: true,
        },
        () => {
          if (this.archiveId) {
            postMessage('archiveIdWasCreated');
          } else {
            this.screenRecording = true;
          }
        },
      );
      this.screenSharingRole = ScreenSharingRoleEnum.PUBLISHER;
      this.publisherScreenRec.on('mediaStopped', () => {
        this.screenSharingRole = ScreenSharingRoleEnum.NONE;
      });
    } catch (error) {
      console.error('Error capturing screen:', error);
    }
  };

  // Publishes a separate screen-sharing Publisher (this.screenPublisher)
  screenSharing = (setScreenSharing) => {
    if (setScreenSharing) {
      setScreenSharing(true);
    }
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported || response.extensionRegistered === false) {
        alert('Screen sharing not supported');
      } else if (response.extensionInstalled === false) {
        alert('Browser requires extension');
      } else {
        // Create a new screen publisher
        this.screenPublisher = OT.initPublisher(
          'ScreenSharingWrapper',
          {
            insertMode: 'append',
            width: '100%',
            height: '100%',
            videoSource: 'screen',
            publishAudio: true,
          },
          this.handleCallback,
        );
        this.screenSharingRole = ScreenSharingRoleEnum.PUBLISHER;

        this.screenPublisher.on('mediaStopped', () => {
          // The user clicked "Stop"
          this.screenSharingRole = ScreenSharingRoleEnum.NONE;
        });

        // Publish screen
        this.session.publish(this.screenPublisher, this.handleCallback);
      }
    });
  };

  stopScreenSharing = (setScreenSharing) => {
    if (setScreenSharing) {
      setScreenSharing(false);
    }
    this.screenSharingRole = ScreenSharingRoleEnum.NONE;
    if (this.screenPublisher) {
      this.screenPublisher.destroy();
      this.screenPublisher = null;
    }
  };

  /**
   * Toggles the *camera* video feed (isSelfVideoOn).
   */
  toggleVideo = () => {
    this.isSelfVideoOn = !this.isSelfVideoOn;
    if (this.cameraPublisher) {
      this.cameraPublisher.publishVideo(this.isSelfVideoOn);
    }
  };

  /**
   * Toggles the *camera* audio feed (isSelfAudioOn).
   */
  toggleAudio = () => {
    this.isSelfAudioOn = !this.isSelfAudioOn;
    if (this.cameraPublisher) {
      this.cameraPublisher.publishAudio(this.isSelfAudioOn);
    }
  };

  handleError = (error) => {
    if (error) {
      // Error code when user did not give permission for using camera
      if (error.code === USER_NO_PERMISSION_ERROR) {
        this.userHasPermission = false;
      }
      throw new Error(error);
    }
  };

  sendMessage = (message) => {
    this.session.signal(
      {
        type: 'msg',
        data: {
          participantId: this.participantId,
          message,
          timestamp: new Date(),
        },
      },
      (error) => {
        if (error) {
          this.handleError(error.message);
        }
        const mutation = `
          mutation{
            createPost(newApi: ${true}, parentId: "${this.chatId
}", parentType: "ACTIVITY_CHAT", data: "${message}") {
              id
            }
          }
        `;
        if (!useFixtures) graphMutation(mutation, {}, true);
      },
    );
  };

  endChat = async () => {
    try {
      if (!useFixtures) {
        await postRequest(CHAT_SERVER, CHAT_ENDPOINTS.END_CHAT, {
          chatId: this.chatId,
        });
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  emergencyEndChat = () => {
    // dispatched when mentor closes tab instead of closing the chat by end conversation button, in the future
    // we can add a flag to remind the mentor to rate the users

    postRequest(CHAT_SERVER, CHAT_ENDPOINTS.END_CHAT, { chatId: this.chatId });
  };

  createMirrorSession = async () => {
    // add await call to server to get the session data and update the data Chat service

    const response = await postRequest(
      CHAT_SERVER,
      CHAT_ENDPOINTS.START_ACTIVITY_SESSION,
    );

    this.APIKey = response.body.apiKey;
    this.token = response.body.token;
    this.sessionId = response.body.sessionId;
  };

  connectToMirrorSession = async () => {
    this.session = await OT.initSession(this.APIKey, this.sessionId);

    this.cameraPublisher = await OT.initPublisher('publisher', {
      publishAudio: true,
      publishVideo: true,
      name: 'publisher',
      insertMode: 'replace',
      width: '100%',
      height: '100%',
      showControls: false,
    });
    // For screen recording
    await this.screenRecordingHelper();
    await this.connectToSession();
    this.session.publish(this.cameraPublisher);
    this.session.publish(this.publisherScreenRec);
  };

  startVideoMonitorRecording = async (activityContext) => {
    try {
      // add call to endpoint to start recording
      if (activityContext) this.activityContext = activityContext;
      if (!this.sessionId) {
        await this.createMirrorSession();
        await this.connectToMirrorSession();
      }

      const response = await postRequest(
        CHAT_SERVER,
        CHAT_ENDPOINTS.START_ACTIVITY_ARCHIVE,
        { sessionId: this.sessionId, activityContext: this.activityContext },
      );

      this.archiveId = response.body.archiveId;

      if (this.screenRecording) {
        postMessage('archiveIdWasCreated');
        this.screenRecording = null;
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  stopVideoMonitorRecording = async () => {
    try {
      if (!this.archiveId) {
        return;
      }
      // add call to endpoint to start recording
      await postRequest(CHAT_SERVER, CHAT_ENDPOINTS.STOP_ACTIVITY_ARCHIVE, {
        archiveId: this.archiveId,
      });

      this.archiveId = null;
    } catch (error) {
      this.handleError(error);
    }
  };

  leaveSession = async (sessionId) => {
    try {
      // add call to the endpoint to end session
      await postRequest(CHAT_SERVER, CHAT_ENDPOINTS.LEAVE_ACTIVITY_SESSION, {
        sessionId: this.sessionId,
      });
      if (this.session) this.session.disconnect();
      this.archiveId = null;
      this.APIKey = null;
      this.token = null;
      this.sessionId = null;
      this.activityContext = null;
    } catch (error) {
      this.handleError(error);
    }
  };

  disconnectFromMirrorSession = async (id) => {
    try {
      // add call to the endpoint to end session
      const archiveId = this.archiveId ? this.archiveId : id;
      const { activityContext } = this;

      if (activityContext && archiveId) {
        await postRequest(CHAT_SERVER, CHAT_ENDPOINTS.END_ACTIVITY_SESSION, {
          archiveId,
          activityContext,
        });
      }
      if (this.session) this.session.disconnect();
      postMessage('archiveWasStopped');
      this.archiveId = null;
      this.APIKey = null;
      this.token = null;
      this.sessionId = null;
      this.activityContext = null;
    } catch (error) {
      this.handleError(error);
    }
  };
}

const instance = new ChatService();

export default instance;