import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    padding: '0 50px',
    gridArea: 'CourseProgress',
    zIndex: 2,
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const StepProgress = styled('ul')(
  {
    listStyle: 'none',
    display: 'flex',
    position: 'relative',
    width: '100%',
    padding: 0,
    margin: 0,
    '&:before, &:after': {
      content: '""',
      height: 4,
      position: 'absolute',
      top: 9,
      zIndex: 100,
    },
  },
  ({
     theme,
     locationIndex,
     skillsAmount,
     oneStep,
     isCourseCompleted,
   }) => {
    const linesAmount = skillsAmount === 1 ? 1 : skillsAmount - 1;
    return {
      justifyContent: oneStep ? 'flex-end' : 'space-between',
      '&:before': {
        width: `calc((100% - 22px) / ${linesAmount} * ${locationIndex})`,
        [theme.RTL ? 'right' : 'left']: 11,
        backgroundColor: theme.palette.novotalk.$novotalkBlue,
      },
      '&:after': {
        width: `calc((100% - 22px) / ${linesAmount} * (${linesAmount} - ${locationIndex}))`,
        [theme.RTL ? 'left' : 'right']: 11,
        backgroundColor: isCourseCompleted ? theme.palette.novotalk.$novotalkBlue : theme.palette.novotalk.$borderColorLight,
      },
    };
  }
);

export const Step = styled('li')(
  {
    display: 'flex',
    flexDirection: 'column',
  },
    (({ first, last, skillsAmount, oneStep, theme }) => ({
        alignItems: first
            ? 'flex-start'
            : last || oneStep
                ? 'flex-end'
                : 'center',
        textAlign: first
            ? theme.RTL
                ? 'right'
                : 'left'
            : last || oneStep
                ? !theme.RTL
                    ? 'right'
                    : 'left'
                : 'center',
        width: first
        ? `calc(100% / ${skillsAmount} / 2 + 10px)`
            : last || oneStep
        ?  `calc(100% / ${skillsAmount} / 2 + 10px)`
        :`calc(100% / ${skillsAmount} - 10px)`
  }))
);

export const Circle = styled('div')(
  {
    display: 'block',
    boxSizing: 'border-box',
    position: 'relative',
    width: 22,
    height: 22,
    margin: 0,
    borderRadius: '50%',
    textAlign: 'center',
    border: '3px solid',
    zIndex: 200,
    '& svg': {
      position: 'absolute',
      left: 2,
      top: 2,
      width: 12,
      height: 12,
    },
  },
  ({ theme, progress }) => {
    switch (progress) {
      case 'completed':
        return {
          borderColor: theme.palette.novotalk.$novotalkBlue,
          backgroundColor: theme.palette.novotalk.$novotalkBlue,
          color: theme.palette.novotalk.$textColorReversed,
        };
      case 'locked':
        return {
          borderColor: theme.palette.novotalk.$borderColorLight,
          backgroundColor: theme.palette.novotalk.$backgroundColor,
        };
      default:
        return {
          borderColor: theme.palette.novotalk.$novotalkBlue,
          backgroundColor: theme.palette.novotalk.$backgroundColor,
        };
    }
  }
);

export const Label = styled('div')(
  {
    maxWidth: '60px', 
    textAlign: 'center',
    '& span': {
      whiteSpace: 'nowrap', 
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  ({ theme, progress }) => {
    switch (progress) {
      case 'completed':
        return {
          color: theme.palette.novotalk.$textColorHighlited,
        };
      default:
        return {
          color: theme.palette.novotalk.$textColorDisabledLight,
        };
    }
  }
);
