import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
const CloseIcon = getIconsByType(iconTypes.CLOSE);

export const StyledCloseIcon = styled(CloseIcon)(
  {
    fontSize: 40,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
  })
);

export const ClicksStopper = styled('div')({});

export const Backdrop = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(100,100,100,0.8)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '1000',
  overflow: 'scroll',
  alignItems: 'center',
  justifyContent: 'center',
});

export const DisplayArea = styled('div')({
  maxWidth: '80%',
  maxHeight: '90%',
  position: 'relative',
});

export const CloseIconWrapper = styled('div')({
  position: 'absolute',
  top: '10px',
  left: '10px',
  cursor: 'pointer',
  zIndex: '1000001',
});
