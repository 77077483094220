import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import SharedInput from '@components/Input';

const selectable = styled('div')({}, ({ isSelected, theme }) => ({
  backgroundColor: isSelected ? theme.palette.novotalk.textColorHighlited : null,
  color: isSelected ? 'white' : 'black',
  cursor: 'pointer',
}));
export const Input = styled(SharedInput)({ width: 200 });
export const Item = styled(selectable)({});
export const ListContainer = styled('div')({
    borderWidth: 1,
    borderRadius: 2,
    borderStyle: 'solid',
    padding: 15,
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'scroll',
    marginRight: '30px',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.borderColorLight,
  })
);

export const AreaHeader = styled('div')({ height: 120 });
export const Title = styled('p')({
  fontSize: '30px',
});

export const StyledArrowRightIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_RIGHT)
)(
  {
    fontSize: 24,
    marginRight: -5,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$activeColor,
  })
);
