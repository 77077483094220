/**
 *
 * PendingUsers
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { ROUTES } from '@shared/constants';
import TextButton from '@components/TextButton';
import Input from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents/Input';
import {
  Wrapper,
  TitleWrapper,
  TitleTop,
  StyledLink,
  StyledNewItemIcon,
  UsersWrapper,
  ActiveUser,
  StyledCheckbox,
  Avatar,
  StyledMessageIcon,
  StyledVideoIcon,
  StyledCTAButton,
} from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class PendingActiveUsers extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: {},
      filter: '',
    };
  }

  onFilterChange = (event) => {
    const { value } = event.target;
    this.setState({ filter: value });
  };

  onDirectInvite = ({ id }) => () => {
    this.props.onStartVideoChat([id.toString()]);
  };

  getFilteredUsers = () => {
    const { filter } = this.state;
    const { users } = this.props;
    if (_.isEmpty(filter)) return users;
    return users.filter(({ fullName }) =>
      fullName.toLowerCase().includes(filter.toLowerCase())
    );
  };

  toggleUserSelection = ({ id }) => () => {
    const { selectedUsers } = this.state;
    if (selectedUsers[id])
      this.setState({ selectedUsers: _.omit(selectedUsers, id) });
    else {
      selectedUsers[id] = true;
      this.setState({ selectedUsers });
    }
  };

  startChat = () => {
    const chatInvitees = _.keys(this.state.selectedUsers).map((id) =>
      id.toString()
    );
    this.props.onStartVideoChat(chatInvitees);
  };

  renderUser = (user) => {
    const { onStartConversation } = this.props;
    return (
      <ActiveUser key={user.id} className='activeUser'>
        <StyledCheckbox
          className='toggleSelection'
          value={Boolean(this.state.selectedUsers[user.id])}
          onChange={this.toggleUserSelection(user)}
        />
        <Avatar className='avatar' src={user.avatar} />
        <Text
          className='name'
          size={TEXT_SIZE.H4}
          textColor={TEXT_COLOR.DARK_BLUE}
        >
          {user.fullName}
        </Text>
        <StyledLink
          to={ROUTES.MESSAGING}
          onClick={() => onStartConversation(user)}
        >
          <StyledMessageIcon className='messageToUserButton' />
        </StyledLink>
        <StyledVideoIcon
          onClick={this.onDirectInvite(user)}
          className='inviteToChatButton'
        />
      </ActiveUser>
    );
  };

  render() {
    const { selectedUsers, filter } = this.state;
    const { onStartConversation, t } = this.props;
    const users = this.getFilteredUsers();
    return (
      <Wrapper>
        <TitleWrapper>
          <TitleTop>
            <Text
              size={TEXT_SIZE.T0}
              textColor={TEXT_COLOR.DARK_BLUE}
              weight={TEXT_WEIGHT.BOLD}
              className='activeUsersTitle'
            >
              {t('Common.Roles.users')}
            </Text>
            <StyledLink
              to={ROUTES.MESSAGING}
              onClick={() => onStartConversation()}
            >
              <TextButton>
                <StyledNewItemIcon />
                <Text size={TEXT_SIZE.T4}>
                  {t('Common.Messaging.newMessage')}
                </Text>
              </TextButton>
            </StyledLink>
          </TitleTop>
          <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED} className='activeUsersSubTitle'>
            {t('Informative.Demanding.startAVideoChat')}
          </Text>
        </TitleWrapper>
        <Input
          fullWidth
          className='filter'
          onChange={this.onFilterChange}
          value={filter}
          placeholder={t('Common.Placeholders.searchUser')}
        />
        <UsersWrapper>
          {users.length === 0 ? (
            <div className='noUsers'>
              <Text>
                {t('Informative.Negative.noUsers')}
              </Text>
            </div>
          ) : (
            users.map(this.renderUser)
          )}
        </UsersWrapper>
        <StyledCTAButton
          className='startChatButton'
          disabled={_.isEmpty(selectedUsers)}
          onClick={this.startChat}
        >
          {t('Informative.Confirming.startVideoChat')}
        </StyledCTAButton>
      </Wrapper>
    );
  }
}

PendingActiveUsers.propTypes = {
  users: PropTypes.array,
  onStartVideoChat: PropTypes.func,
  onStartConversation: PropTypes.func,
};

export default compose(
    withTranslation()
)(PendingActiveUsers);
