import React from 'react';
import PropTypes from 'prop-types';
import Container from '@components/Container';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import ProgressBar from '@components/ProgressBar';
import {
  Wrapper,
  HeaderContainer,
  TipsListContainer,
  TipContainer,
  getStyledIcon,
  IconWrapper,
} from './styles';
import { getExerciseTips } from '@containers/User/helpers/monitorErrors';
import {useTranslation} from "react-i18next";

const ExerciseTips = ({ exerciseName, progressBarProps, insights }) => {
  const { mastery, progress } = progressBarProps;
  const {t} = useTranslation()

  const tipsList = getExerciseTips(insights);

  return (
    <Wrapper>
      <HeaderContainer>
        <Text
          size={TEXT_SIZE.T1}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight='bold'
          textTransform='capitalize'
          className='popup-title'
        >
          {exerciseName}
        </Text>
        <Container className='popup-progress' alignItems='baseline'>
          <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DARK_BLUE}
            margin='0 5px 0 0'
            className='popup-progress-text'
          >
            {t('Errors.masteryLevel')}
          </Text>
          <ProgressBar {...progressBarProps} width={300} height={10} />
          {progress > 0 && (
            <Text
              size={TEXT_SIZE.H4}
              textColor={
                progress >= mastery
                  ? TEXT_COLOR.ACTIVE
                  : TEXT_COLOR.DISABLED_LIGHT
              }
              margin='0 5px'
              className='popup-progress-text'
            >
              {`${progress}%`}
            </Text>
          )}
          {/* <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DARK_BLUE}
            className='popup-progress-text'
          >
            {`${progress > 0 ? '/' : ''} ${mastery}%`}
          </Text> */}
        </Container>
      </HeaderContainer>
      <TipsListContainer>
        {tipsList.map(({ errorId, iconName, text, errorName }) => {
          const TipIcon = getStyledIcon(iconName);
          return (
            <TipContainer key={errorId}>
              <IconWrapper>
                <TipIcon />
              </IconWrapper>
              <Text
                size={TEXT_SIZE.T3}
                textColor={TEXT_COLOR.DARK_BLUE}
                weight='bold'
                margin='0 0 6px'
              >
                {errorName}
              </Text>
              <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
                {text}
              </Text>
            </TipContainer>
          );
        })}
      </TipsListContainer>
    </Wrapper>
  );
};

ExerciseTips.propTypes = {
  exerciseName: PropTypes.string,
  progressBarProps: PropTypes.object,
  insights: PropTypes.array,
};

export default ExerciseTips;
