import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  ClosedArea,
  InputArea,
  StyledTextArea,
  StyledCTAButton,
} from './styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class MessageArea extends Component {
  constructor(props) {
    super(props);
    this.messageTextArea = createRef();
    this.state = {
      value: '',
    };
  }

  componentDidMount() {
    this.messageTextArea.current?.focus();
  }

  componentDidUpdate() {
    this.messageTextArea.current?.focus();
  }

  handleChange = (ev) => {
    const { value } = ev.target;
    this.setState({ value });
  };

  handleSubmit = () => {
    const { sendMessage } = this.props;
    const { value } = this.state;
    if (value.trim().length) {
      sendMessage(value.trim());
    }
    this.setState({ value: '' });
  };

  render() {
    const {
      currentConversation,
      conversationPartner,
    } = this.props;
    const { value } = this.state;
    const isConversationClosed =
      !_.isEmpty(currentConversation) &&
      Boolean(currentConversation.Thread.closed);
    const isClosedBySelf =
      isConversationClosed &&
      currentConversation.Users.find(
        ({ id }) => id === currentConversation.Thread.closed.by
      )?.self;
    const isAreaDisabled = _.isEmpty(conversationPartner);
    return isConversationClosed ? (
      <ClosedArea>
        <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.DISABLED}>
          {this.props.t(`Informative.Notifying.${isClosedBySelf ? 'endedBySelf' : 'endedBy'}`) +
            ` ${isClosedBySelf ? '' : conversationPartner?.fullName}`}
        </Text>
      </ClosedArea>
    ) : (
      <InputArea disabled={isAreaDisabled}>
        <StyledTextArea
          rows={3}
          value={value}
          onChange={this.handleChange}
          placeholder={this.props.t('Common.Placeholders.message')}
          disabled={isAreaDisabled}
          ref={this.messageTextArea}
        />
        <StyledCTAButton
          action
          onClick={this.handleSubmit}
          disabled={isAreaDisabled}
        >
          {this.props.t('Common.Statuses.send')}
        </StyledCTAButton>
      </InputArea>
    );
  }
}

MessageArea.propTypes = {
  currentConversation: PropTypes.object,
  sendMessage: PropTypes.func,
  conversationPartner: PropTypes.object,
};

export default compose(
    withTranslation()
)(MessageArea);
