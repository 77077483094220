import { styled } from '@mui/material';
import { getIconsByType, iconTypes } from '@services/icons';
import { getResponsiveCSS, getFlexibleSize } from '@utils/cssHelpers';
import CTAButton from '@components/CTAButton';

const PauseIcon = getIconsByType(iconTypes.PAUSE);

export const StyledPauseIcon = styled(PauseIcon)(
  {
    cursor: 'pointer',
    fontSize: 24,
    padding: 4,
    borderRadius: 2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
    backgroundColor: theme.palette.novotalk.$buttonColorDisabled,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$pauseIconColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$pauseIconColorActive,
    },
  }),
);

export const SubHeaderWrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'flex-start',
    width: '100%',
    flexShrink: 0,
    paddingTop: '28px',
    borderTop: '1px solid',
  },
  ({ theme }) => ({
      direction: theme.RTL ? 'rtl' : 'ltr',
      borderColor: theme.palette.novotalk.$borderColorLight,
  }),
);

export const Instructions = styled('div')(
  {
    wordWrap: 'break-word',
    display: 'block',
    maxHeight: 75,
    overflow: 'auto',
  },
  ({ theme }) => ({
    [theme.RTL ? 'paddingLeft' : 'paddingRight']: 20,
  }),
);

export const ProgressAndButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minHeight: 26,
  direction: 'ltr',
  flexDirection: 'column',
});

export const MirrorWrapper = styled('div')(
  {
    flexShrink: 0,
    alignSelf: 'center',
    width: '200px',
  },
  ({ displayMirror }) => ({
    margin: displayMirror ? '20px' : '0 0 5px 0',
    height: displayMirror ? '150px' : 0,
  }),
);

export const MonitorOverlay = styled('div')(
  {
    position: 'absolute',
    top: '0px',
    minHeight: '120px',
    zIndex: '99',
    alignItems: 'center',
    justifyContent: 'center',
  },
  getResponsiveCSS('width', 780, 1130),
  ({ theme, monitorActive }) => ({
    backgroundColor: theme.palette.novotalk.$monitorOverlayColor,
    display: monitorActive ? 'none' : 'flex',
  }),
);

export const Wrapper = styled('div')({
    position: 'relative'
})

export const MonitorWrapper = styled('div')(
  {
    position: 'relative',
    flexShrink: 0,
    minHeight: '170px',
  },
  ({ withMirror }) => ({
    marginTop: withMirror ? 25 : 45,
  }),
);

export const ProgressWrapper = styled('div')({
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 15,
});

export const MirrorWithVideoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: getFlexibleSize(780, 1130),
  height: getFlexibleSize(540, 800),
});

export const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  maxWidth: getFlexibleSize(380, 555),
  maxHeight: getFlexibleSize(270, 400),
});

export const LargeMirrorWrapper = styled('div')({
  width: '100%',
  height: '100%',
  maxWidth: getFlexibleSize(380, 555),
  maxHeight: getFlexibleSize(270, 400),
});

export const VideoWrapper = styled('div')(
  {
    width: getFlexibleSize(380, 555),
    height: getFlexibleSize(270, 400),
    display: 'flex',
    position: 'relative',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$textColor,
  }),
);

export const VideoOverlay = styled('div')(
  {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ theme, exercisePaused, exerciseInProgress, isVideoStarted }) => ({
    backgroundColor:
      (isVideoStarted && exerciseInProgress && !exercisePaused)
        ? 'none'
        : theme.palette.novotalk.$monitorOverlayColor,
    '& > div': {
      display: isVideoStarted && exerciseInProgress && !exercisePaused ? 'none' : 'inherit',
    },
    '&:hover': {
      backgroundColor: exerciseInProgress && isVideoStarted ? theme.palette.novotalk.$monitorOverlayColor : 'none',
      '& > div': {
        display: 'inherit',
      },
    },
  }),
);

export const VideoControlButton = styled(CTAButton)({}, ({ onlyIcon }) => ({
  width: onlyIcon ? 50 : 120,
}));

export const MonitorButton = styled(CTAButton)(
  {},
  ({ isHide }) => ({
    display: isHide ? 'none' : 'flex',
  }),
);

export const VideoPauseIcon = styled(PauseIcon)(
  {
    cursor: 'pointer',
    fontSize: 37,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  }),
);

export const InhaleInstructionWrapper = styled('div')({
  bottom: 0,
  textAlign: 'center',
  position: 'absolute',
  width: '100%',
  zIndex: '10',
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CircularProgressBarWrapper = styled('div')({
  marginBottom: 20,
});
export const MainWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexShrink: 0,
});
