import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CustomTextInput from '@components/CustomTextInput';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { PasswordHint } from '../../../Authentication/PasswordHint';
import {
  formHasErrors,
  getErrorMessageId,
  getPasswordStrength,
  fieldTypes,
  checkStrengthPassword,
} from '../../../Authentication/validate';
import {
  Wrapper,
  ContentContainer,
  Header,
  InputContainer,
  HintContainer,
  StyledCTAButton,
  InputContainerAutoHeight,
} from './styles';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      values: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      errors: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      isTooltipShown: false,
      passwordStrength: -1,
      disableSubmit: true,
    };
  }

  getErrors = () => {
    const { values, passwordStrength } = this.state;
    const newErrors = {};

    _.forEach(values, (fieldValue, fieldName) => {
      const confirmField =
        fieldName === 'confirmPassword' ? values.newPassword : null;
      const options = {
        isWeak:
           fieldName === 'newPassword' && passwordStrength !== 2,
      };
      const messageId = getErrorMessageId(
        fieldTypes.password,
        fieldValue,
        confirmField,
        options,
      );
      newErrors[fieldName]  = messageId
        ? this.props.t(`Errors.${messageId}`)
        : null;
    });

    return newErrors;
  };

  getPasswordHint = () => {
    const { values: { newPassword }, errors } = this.state;
    if (!newPassword.length || errors.newPassword) return null;
    const errorsList = checkStrengthPassword(newPassword);
    return (
      <HintContainer>
        <PasswordHint errors={errorsList} />
      </HintContainer>
    );
  };

  handleFocus = (event) => {
    const { name } = event.target;
    const { userHasRole } = this.props;
    const { isTooltipShown, passwordStrength } = this.state;
    if (
      userHasRole &&
      name === 'newPassword' &&
      !isTooltipShown &&
      passwordStrength !== 2
    ) {
      this.setState({ isTooltipShown: true });
    }
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [name]: null,
      },
    }));
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    const { userName } = this.props;
    const { values: { newPassword } } = this.state;
    if (name === 'newPassword') {
      const strength = value.length ? getPasswordStrength(value, userName.split(' ')) : -1;
      this.setState({ passwordStrength: strength });
    }
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
    const errorsList = checkStrengthPassword(newPassword);
    if (!errorsList.length) {
      this.setState({ disableSubmit: false });
    }
  };

  handleSubmit = () => {
    const { onSubmitPassword, onClose } = this.props;
    const { values } = this.state;
    const formErrors = this.getErrors();
    const loading = !formHasErrors(formErrors);

    if (loading) {
      onSubmitPassword(values.oldPassword, values.newPassword);
      onClose();
    }
    this.setState({
      errors: formErrors,
      loading,
    });
  };

  render() {
    const {t} = this.props
    const { values, errors, disableSubmit } = this.state;
    const commonProps = (type) => ({
      id: type,
      name: type,
      type: 'password',
      label: t(`Authentication.Password.${type}`),
      error: errors[type],
      value: values[type],
      onChange: this.handleChange,
      onFocus: this.handleFocus,
    });

    return (
      <Wrapper>
        <ContentContainer>
          <Header>
            <Text size={TEXT_SIZE.H1} textColor={TEXT_COLOR.DARK_BLUE}>
              {t('Authentication.Password.changePassword')}
            </Text>
          </Header>
          <InputContainer>
            <CustomTextInput {...commonProps('oldPassword')} autofocus />
          </InputContainer>
          <InputContainerAutoHeight>
            <CustomTextInput {...commonProps('newPassword')} />
            {this.getPasswordHint()}
          </InputContainerAutoHeight>
          <InputContainer>
            <CustomTextInput {...commonProps('confirmPassword')} />
          </InputContainer>
          <StyledCTAButton
            id='password-submit-button'
            expanded
            onClick={this.handleSubmit}
            disabled={disableSubmit}
          >
            {t('Actions.Instance.submit')}
          </StyledCTAButton>
        </ContentContainer>
      </Wrapper>
    );
  }
}

ChangePassword.propTypes = {
  userName: PropTypes.string,
  onSubmitPassword: PropTypes.func,
  onClose: PropTypes.func,
  userHasRole: PropTypes.bool,
};

export default compose(
    withTranslation()
)(ChangePassword);
