import gql from 'graphql-tag';
import { graphQuery, graphMutation } from '@services/commService';
import { getTimeRange } from '@utils/timeHelpers';
import ChatService from '@services/chatService';
import { SLIPPING_THRESHOLD_MS } from '../constants';

let cachedUserMap = {};
let cachedSkills = [];

const isPassedThreshold = (timestamp) => {
  const msFromNow = Date.now() - new Date(+timestamp).getTime();
  return msFromNow < SLIPPING_THRESHOLD_MS;
};

export const sendDismissActivity = async ({ type, id }, reason) => {
  const mutation = gql`mutation{
       createFeedback(
          text:"""${reason}""",
          isDismiss:true
          activity:{
            type:${type},
            id:"${id}"
          }) {
         id
       }
     }`;
  const res = await graphMutation(mutation);

  return res;
};

const isActivityInThreshold = ({ activityCreated }) => isPassedThreshold(activityCreated.at);
const getHistoryQueryString = (hours) => {
  const dateRange = getTimeRange(hours).join(',');
  return `
  threads(parent:{type:ACTIVITY_PRACTICE },range:[0,50],dateRange:[${dateRange}]) {
    createdAt
    Posts{
      createdAt
      payload
      self
    }
    Activity {
      id
      type
      userId
      context
      entity {
        name
        id
      }
      activityCreated {
        by
        at
      }
      visits {
        createdAt
      }
    }
  }
  `;
};

const queryPendingActivitiesAndPosts = async (hours) => {
  const historyQuery = getHistoryQueryString(hours);

  const query = gql`
  {
    users{
      fullName
      avatar
      id
    }
    skills: entitySkills{
      id
      name
    }
    activities: activityPractices{
      id
      type
      userId
      context
      entity {
        name
        id
      }
      activityCreated {
        by
        at
      }
      visits {
        createdAt
      }
      Threads {
        Posts {
          createdAt
          payload
        }
      }
      summary: shortSummary {
        hasPassed
      }
    }
    feedbackHistory: ${historyQuery}
  }
  `;

  const res = await graphQuery(query);
  return res.data;
};

const addSkillToActivity = (skills) => (activity) => {
  if (activity.context) {
    const activitySkillId = activity.context.SKILL.id;
    const skill = skills.find(({ id }) => id === activitySkillId);
    return {
      ...activity,
      skill,
    };
  }

  return activity;
};

const addUserFromUserMap = (usersMap) => (activity) => ({
  ...activity,
  user: usersMap[activity.userId],
});

const getRequireFeedbackActivities = ({ activities, skills }, usersMap) => activities
  .filter(({ Threads }) => Threads.length === 0)
  .sort(
    (actA, actB) => new Date(actA.activityCreated.at).getTime()
        - new Date(actB.activityCreated.at).getTime(),
  )
  .map(addUserFromUserMap(usersMap))
  .map(addSkillToActivity(skills));

const getActivityHistory = ({ feedbackHistory }, usersMap) => feedbackHistory
  .filter(({ Posts }) => Posts.some(({ self }) => self))
  .map(({ createdAt, Posts, Activity }) => ({
    ...Activity,
    createdAt,
    feedbackStatus: {
      type: Posts[0].payload.isDismiss ? 'DISMISSED' : 'SENT',
      text: Posts[0].payload.text,
    },
  }))
  .map(addUserFromUserMap(usersMap))
  .map(addSkillToActivity(cachedSkills));

export const getHistoryFromHours = async (hours) => {
  const historyQuery = getHistoryQueryString(hours);
  const query = gql`
  {
    feedbackHistory:${historyQuery}
  }
  `;

  const res = await graphQuery(query);

  const activityHistory = getActivityHistory(res.data, cachedUserMap);

  return activityHistory;
};

const getUserMap = ({ users }) => users.reduce((usersMap, currentUser) => {
  const updatedUsersMap = usersMap;
  updatedUsersMap[currentUser.id] = currentUser;
  return updatedUsersMap;
}, {});

export const getPageData = async (hours) => {
  const data = await queryPendingActivitiesAndPosts(hours);
  const usersMap = getUserMap(data);
  cachedUserMap = usersMap;
  cachedSkills = data.skills;

  const activeUsers = data.users;
  const activities = getRequireFeedbackActivities(data, usersMap);

  const activityHistory = getActivityHistory(data, usersMap);

  return {
    activityHistory,
    activities,
    activeUsers,
  };
};

export const getSessionId = async (inviteList) => {
  const sessionId = await ChatService.initiateChat(JSON.stringify(inviteList));
  return sessionId;
};
