import {styled} from "@mui/material";

export const Container = styled('div')({});
export const MediaArea = styled('div')({});
export const MediaThumbnail = styled('div')({
  height: 295,
  display: 'flex',
  backgroundColor: 'lightgrey',
  alignItems: 'center',
  justifyContent: 'center',
});
