import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '@shared/constants';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import TextButton from '@components/TextButton';

import {
  ScreenContainer,
  WithHeaderComponentContainer,
  HeaderContainer,
  MainContainer,
  TextContainer,
  LinkContainer,
  StyledLink,
} from './styles';
import { onSendPasswordLink } from '../ForgotPassword/actions';
import reducer from '../ForgotPassword/reducer';
import saga from '../ForgotPassword/saga';
import {useTranslation} from "react-i18next";

export const ResetEmailSent = ({ history, sendPasswordLink }) => {
  const {t} = useTranslation()
  return (
      <ScreenContainer>
        <WithHeaderComponentContainer>
          <HeaderContainer>
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H1}>
              {t('Authentication.Email.checkEmail')}
            </Text>
          </HeaderContainer>
          <MainContainer>
            <TextContainer>
              <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
                {t('Authentication.Password.resetInstruction')}
              </Text>
            </TextContainer>
            <LinkContainer>
              <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H4}>
                {t('Authentication.Email.notReceive')}
              </Text>
              <TextButton
                  id='resend_email_link'
                  onClick={() => sendPasswordLink(history.location.state.email)}
              >
                <Text size={TEXT_SIZE.H4} margin='0 5px'>
                  {t('Authentication.Email.resend')}
                </Text>
              </TextButton>
            </LinkContainer>
          </MainContainer>
          <LinkContainer>
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
              {t('Actions.Navigation.backTo')}
            </Text>
            <StyledLink id='sign_in_link' to={ROUTES.SIGN_IN}>
              <TextButton>
                <Text size={TEXT_SIZE.H4}>
                  {t('Actions.Auth.signIn')}
                </Text>
              </TextButton>
            </StyledLink>
          </LinkContainer>
        </WithHeaderComponentContainer>
      </ScreenContainer>
  );
}

ResetEmailSent.propTypes = {
  history: PropTypes.object.isRequired,
  sendPasswordLink: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  const serverError = state.getIn(['errors', 'serverError']);
  return { serverError };
};

export const mapDispatchToProps = (dispatch) => ({
  sendPasswordLink: (email) => {
    dispatch(onSendPasswordLink(email));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'sendPasswordLink', saga });
const withReducer = injectReducer({ key: 'sendPasswordLink', reducer });

export default compose(
  withConnect,
  withRouter,
  withReducer,
  withSaga
)(ResetEmailSent);
