import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { closePopUp, openPopUp } from '../../containers/PopUp/actions';
import POPUP_TYPES from '../../containers/PopUp/types';
import { ROUTES } from '@shared/constants';
import saga from './saga';
import reducer from './reducer';
import { checkForWaitingVideoCall } from './actions';
import { setRedirectUrl } from '../Redirector/actions';
import {
  getFullConversationAction,
  getConversationsAction,
} from '../../../App/containers/Messaging/actions';
import { StyledVideoIcon, alertButtonCSS } from './styles';
import { clearVideoChatError } from '@shared/VideoChat/actions';
import {withTranslation} from "react-i18next";

export class PollingService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingVideoChat: false
    };
  }

  componentDidMount() {
    if (this.props.userSignedIn) {
      this.startVideoChatPolling();
      this.startConversationsPolling();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      pendingVideoChatInfo,
      dispatchOpenPopup,
      dispatchClosePopup,
      connectedToChat,
      userSignedIn,
      connectedToMirror,
      currentConversation,
    } = this.props;
    if (!pendingVideoChatInfo && this.state.pendingVideoChat) {
      dispatchClosePopup();
      this.setVideoChatPending(false)
      this.startVideoChatPolling();
    }
    if (!_.isEqual(prevProps.pendingVideoChatInfo, pendingVideoChatInfo) && pendingVideoChatInfo) {
      this.setVideoChatPending(true)
      const props = this.generateJoinVideoPopupProps();
      dispatchOpenPopup(props);
    }
    if (connectedToChat || connectedToMirror || !userSignedIn) {
      this.stopVideoChatPolling();
    } else {
      this.startVideoChatPolling();
    }
    if (userSignedIn) {
      this.startConversationsPolling();
      if (_.isEmpty(currentConversation) && this.fullConversationPoller) {
        this.stopFullConversationPolling();
      } else if (
        !_.isEmpty(currentConversation) &&
        !this.fullConversationPoller
      ) {
        this.startFullConversationPolling();
      } else if (
        _.get(currentConversation, 'id', undefined) !==
        _.get(prevProps, 'currentConversation.id')
      ) {
        this.stopFullConversationPolling();
        this.startFullConversationPolling();
      }
    } else {
      this.stopConversationsPolling();
      this.stopFullConversationPolling();
    }
  }

  componentWillUnmount() {
    this.stopVideoChatPolling();
    this.stopConversationsPolling();
    this.stopFullConversationPolling();
  }

  setVideoChatPending(value) {
    this.setState({
      pendingVideoChat: value
    })
  }

  startVideoChatPolling = () => {
    if (!this.videoChatPoller)
      this.videoChatPoller = setInterval(() => {
        this.props.dispatchCheckForWaitingVideoCall();
      }, 20000);
  };

  stopVideoChatPolling = () => {
    clearInterval(this.videoChatPoller);
    this.videoChatPoller = null;
  };

  startConversationsPolling = () => {
    if (!this.conversationsPoller) {
      this.conversationsPoller = setInterval(() => {
        this.props.dispatchGetConversations();
      }, 20000);
    }
  };

  stopConversationsPolling = () => {
    clearInterval(this.conversationsPoller);
    this.conversationsPoller = null;
  };

  startFullConversationPolling = () => {
    const { dispatchGetFullConversation, currentConversation } = this.props;
    if (!this.fullConversationPoller) {
      this.fullConversationPoller = setInterval(() => {
        dispatchGetFullConversation(currentConversation.id);
      }, 10000);
    }
  };

  stopFullConversationPolling = () => {
    clearInterval(this.fullConversationPoller);
    this.fullConversationPoller = null;
  };

  generateJoinVideoPopupProps() {
    const {
      dispatchSetRedirectUrl,
      pendingVideoChatInfo,
      dispatchClosePopup,
      dispatchClearVideoChatError,
    } = this.props;

    const titleText = <StyledVideoIcon />;

    const bodyText = (
      <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.DARK_BLUE} weight='900'>
        {this.props.t('Video.videoChatStart', {
          name: pendingVideoChatInfo.createdBy,
        })}
      </Text>
    );

    const buttonText = (
      <Text weight='normal'>
        {this.props.t('Actions.Instance.join')}
      </Text>
    );

    const buttonProps = {
      className: alertButtonCSS,
      action: true,
    };

    const width = 575;

    /**
    * Close dialog and open video chat
    * before joining video chat we first check if video chat info exist, if not then we close the popup
    * @returns {void}
    */
    const onClose = () => {
      dispatchClearVideoChatError();
      if (pendingVideoChatInfo?.id) { dispatchSetRedirectUrl(`${ROUTES.VIDEO_CHAT}/${pendingVideoChatInfo.id}`) }
      else {
        dispatchClosePopup();
        this.startVideoChatPolling();
      };
    }
    const hideCloseIcon = true;
    const disableBackdrop = true;

    return {
      titleText,
      bodyText,
      buttonText,
      width,
      buttonProps,
      onClose,
      hideCloseIcon,
      disableBackdrop,
    };
  }

  render() {
    return null;
  }
}

PollingService.propTypes = {
  dispatchSetRedirectUrl: PropTypes.func,
  dispatchOpenPopup: PropTypes.func,
  dispatchClosePopup: PropTypes.func,
  dispatchCheckForWaitingVideoCall: PropTypes.func,
  pendingVideoChatInfo: PropTypes.object,
  connectedToChat: PropTypes.bool,
  connectedToMirror: PropTypes.bool,
  userSignedIn: PropTypes.bool,
  currentConversation: PropTypes.object,
  dispatchGetConversations: PropTypes.func,
  dispatchGetFullConversation: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const { pendingVideoChatInfo } = state.getIn(['pollingService']).toJS();
  const connectedToChat = state.getIn(['videoChat', 'connectedToChat']);
  const connectedToMirror = state.getIn(['videoMirror', 'connectedToMirror']);
  const userInfo = state.getIn(['app', 'authentication', 'user']).toJS();
  const messagingState = state.getIn(['messaging'])
    ? state.getIn(['messaging']).toJS()
    : {};
  const { currentConversation } = messagingState;
  const userSignedIn = !_.isEmpty(userInfo);
  return {
    pendingVideoChatInfo,
    connectedToChat,
    userSignedIn,
    connectedToMirror,
    currentConversation,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchClosePopup: () =>
      dispatch(closePopUp()),
    dispatchOpenPopup: (props) =>
      dispatch(openPopUp(POPUP_TYPES.ALERT, props)),
    dispatchCheckForWaitingVideoCall: () =>
      dispatch(checkForWaitingVideoCall()),
    dispatchSetRedirectUrl: (url) =>
      dispatch(setRedirectUrl(url)),
    dispatchGetConversations: () =>
      dispatch(getConversationsAction()),
    dispatchGetFullConversation: (id) =>
      dispatch(getFullConversationAction(id)),
    dispatchClearVideoChatError: () =>
      dispatch(clearVideoChatError()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'pollingService', reducer });
const withSaga = injectSaga({ key: 'pollingService', saga });

export default compose(withReducer, withSaga, withConnect, withTranslation())(PollingService);
