import { activityContext } from './activityContext';

export const gentleVoice = {
    monitor: {
        type: "LOUDNESS",
        inhalation: {
            on: true,
            value: 2
        },
        video: {
            on: false,
            url: null,
            id: null
        },
        mirror: {
            on: false
        },
        speedometer: {
            on: false
        }
    },
    pattern: {
        type: "GENTLE_VOICE",
        productionDuration: 4
    },
    errors: {
        on: true,
        masteryLevel: 100,
        production: {
            tooShort: {
                on: true,
                type: "EASY"
            },
            notLoud: {
                on: false,
                type: "EASY"
            },
            notGentle: {
                on: true,
                type: "EASY"
            },
            noPatern: {
                on: false,
                type: "EASY"
            }
        },
        specific: {
            beginning: {
                nonGentleOnset: {
                    on: true,
                    type: "EASY"
                },
                notGentle: {
                    on: false,
                    type: "EASY"
                },
                volumeControl: {
                    on: false,
                    type: "EASY"
                }
            },
            peak: {
                notLoud: {
                    on: false,
                    type: "EASY"
                },
                softPeak: {
                    on: true,
                    type: "EASY"
                },
                execessivePeak: {
                    on: false,
                    type: "EASY"
                }
            },
            end: {
                volumeControl: {
                    on: false,
                    type: "EASY"
                },
                nonGentleOffset: {
                    on: true,
                    type: "EASY"
                }
            }
        },
        types: [
            {
                type: "tooShortProduction",
                difficulty: "EASY"
            },
            {
                type: "nonGentleOnset",
                difficulty: "EASY"
            },
            {
                type: "nonGentleOffset",
                difficulty: "EASY"
            },
            {
                type: "softPeak",
                difficulty: "EASY"
            },
            {
                type: "notGentleProduction",
                difficulty: "EASY"
            }
        ]
    },
    updateMonitorResults: () => { },
    activityContext
};