import { MentorActivityLog } from '../mentor';
import { Participants } from '../participants';
import { useParams } from 'react-router-dom';
import { VideoChatParticipantsWrapper } from './styles';
import { formatParticipants } from './helpers';

export const VideoChatParticipants = ({
  isGroupVideoChat,
  participants,
  mentor,
}) => {
  const { id } = useParams();
  const formattedParticipants = formatParticipants(participants, mentor?.id, id);
  return (
    <VideoChatParticipantsWrapper
      isGroupVideoChat={isGroupVideoChat}
    >
      <Participants
        participants={formattedParticipants}
        currentUserId={id}
      />
      <MentorActivityLog
        mentor={mentor}
        currentUserId={id}
      />
    </VideoChatParticipantsWrapper>
  );
};
