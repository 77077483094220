import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getFlexibleSize, getResponsiveCSS } from '@utils/cssHelpers';

export const getStyledIcon = (iconName) => getIconsByType(iconTypes[iconName]);

export const IconWrapper = styled('div')({
  margin: '15px auto',
  width: 54,
  '@media(min-width: 1280px)': {
    width: getFlexibleSize(54, 64),
  },
  '@media(min-width: 1920px)': {
    width: 64,
  },
});

export const Wrapper = styled('div')(
  {
    width: 900,
    minHeight: 360,
    padding: '38px 40px 28px 40px',
    '@media(min-width: 1280px)': {
      minHeight: getFlexibleSize(360, 390),
    },
    '@media(min-width: 1920px)': {
      minHeight: 390,
    },
    borderRadius: 4,
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    color: theme.palette.novotalk.$textColorDark,
  })
);

export const HeaderContainer = styled('div')(
  {
    borderBottom: '1px solid',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& span.popup-title': {
      marginBottom: 18,
    },
    '& div.popup-progress': {
      alignSelf: 'center',
      marginBottom: 25,
    },
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$textColorDisabled,
  })
);

export const TipsListContainer = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderTop: '1px solid',
    padding: '0 15px',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const TipContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: '40%',
  padding: '15px 20px',
  textAlign: 'center',
  lineHeight: '1.2em',
  '@media(min-width: 1920px)': {
    '& > span': {
      lineHeight: '23px',
    },
  },
});
