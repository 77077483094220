import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  loading: true,
  chatId: '',
  sessionInfo: {
    type: 'CLINICAL',
    sessionId: '',
    users: [],
  },
  error: null,
  isSelfVideoOn: true,
  isSelfAudioOn: true,
  textMessages: [],
  connectedToChat: false,
  showMonitor: false,
  monitorProps: {},
  openDropdown: false,
  monitorSettings: false,
  monitorActive: false,
  microphoneStarted: false,
  exercisePaused: false,
});

const parseSessionInfo = (sessionInfo) => {
  const { users } = sessionInfo;
  const parsedUsers = users?.map((user) => ({
    ...user,
    hasAudio: true,
    hasVideo: true,
  }));
  return fromJS({
    ...sessionInfo,
    users: parsedUsers ?? [],
  });
};

const updateUsers = (users, { participantId, properties }) => {
  const usersToUpdate = users.toJS();
  const updatedUsers = usersToUpdate.map((user) => {
    if (user.participantId === participantId) {
      return {
        ...user,
        ...properties,
      };
    }
    return user;
  });
  return fromJS(updatedUsers);
};

function chatReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_SHOW_MONITOR_SETTINGS:
      return state.set('monitorSettings', action.value);
    case TYPES.SET_MONITOR_PROPS_VIDEO_CHAT:
      return state.set('monitorProps', action.value).set('openDropdown', false).set('monitorSettings', false);
    case TYPES.SET_SHOW_MONITOR_VIDEO_CHAT:
      return state.set('showMonitor', action.value).set('openDropdown', action.showDropdown)
    case TYPES.SET_CHAT_INFO: {
      const { users } = action.sessionInfo;
      if (users) {
        const sessionInfo = parseSessionInfo(action.sessionInfo);
        return state.set('sessionInfo', sessionInfo).set('loading', false);
      }
      return state.set('error', 'no users').set('loading', false);
    }
    case TYPES.SET_EXERCISE_PAUSED_FLAG:
      return state.set('exercisePaused', action.value);
    case TYPES.SET_MONITOR_ACTIVE_FLAG:
      return state.set('monitorActive', action.value);
    case TYPES.SET_MICROPHONE_ACTIVE_FLAG:
      return state.set('microphoneStarted', action.value);
    case TYPES.VIDEO_CHAT_ERROR:
      return state.set('error', action.error).set('loading', false);
    case TYPES.CLEAR_VIDEO_CHAT_ERROR:
      return state.set('error', null);
    case TYPES.TOGGLE_CHAT_AUDIO:
      return state.set('isSelfAudioOn', !state.get('isSelfAudioOn'));
    case TYPES.TOGGLE_CHAT_VIDEO:
      return state.set('isSelfVideoOn', !state.get('isSelfVideoOn'));
    case TYPES.TEXT_MESSAGE_RECEIVED: {
      const textMessages = state.get('textMessages').toJS();
      textMessages.push(action.message);
      return state.set('textMessages', fromJS(textMessages));
    }
    case TYPES.SET_VIDEO_CHAT_CONNECTION_STATUS_TO_CONNECTED:
      return state.set('connectedToChat', true);
    case TYPES.SET_VIDEO_CHAT_CONNECTION_STATUS_TO_DISCONNECTED:
      return state.set('connectedToChat', false);
    case TYPES.SET_CHAT_ID:
      return state.set('chatId', action.chatId);
    case TYPES.CHANGE_STREAM_PROPERTY: {
      const updatedUsers = updateUsers(
        state.getIn(['sessionInfo', 'users']),
        action,
      );
      const newState = state.setIn(['sessionInfo', 'users'], updatedUsers);
      return newState;
    }
    default:
      return state;
  }
}

export default chatReducer;
